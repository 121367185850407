<template>
    <section id="tlment">
        <div class="tl-ctn">
            <div class="tl-ctnLeft">
                <VideoTelechargement/>
            </div>

            <div class="tl-ctnRight">
                <div>
                    <h1 style="display: none;"></h1>
                    <h2 class="title-h2 text-focus-in">{{$t('titleTelechargement')}}</h2>
                    <div class="form-ctn">
                        <p>{{$t('dataTelechargement')['0']}}</p>
                        <div>
                            <!--  DOWNLOAD FORM  -->
                            <form id="tl-form" @submit.prevent="onSubmit">
                                <div class="form-group d-grid">
                                    <span>
                                        <input id="Brochure" type="checkbox" class="el-download" v-model="formData.brochure">
                                        <label class="txtElement" for="Brochure">{{$t('dataTelechargement')['1']}}</label>
                                    </span>
                                    <span>
                                        <input id="Perspective" type="checkbox" class="el-download" v-model="formData.perspective">
                                        <label class="txtElement" for="Perspective">{{$t('dataTelechargement')['2']}}</label>
                                    </span>
                                    <!-- ID HARMONY DANS LA BASE DE DONNEE  -->
                                    <span style="visibility: hidden; position: absolute;" >
                                        <input id="site_name" type="text" class="el-download" v-model="formData.url">
                                    </span>
                                    
                                </div>
                                <div class="form-group">
                                    <input type="text" v-model="formData.nom" id="nom" class="form-item-input" required>
                                    <label for="nom" class="form-item-label">{{$t('dataFormT')['0']}}</label>
                                </div>
                         
                                <div class="form-group">
                                    <input type="text" v-model="formData.prenom" id="prenom" class="form-item-input" required>
                                    <label for="prenom" class="form-item-label">{{$t('dataFormT')['1']}}</label>
                                </div>
                                <div class="form-group">
                                    <input type="email" v-model="formData.email" id="email" class="form-item-input" required>
                                    <label for="email" class="form-item-label txt-email">{{$t('dataFormT')['2']}}</label>
                                </div>

                                <div class="form-group form-condition">
                                    <label for="condition" class="opacity-50">{{$t('dataFormT')['3']}}</label>
                                    <input type="checkbox" v-model="formData.rgpd" id="condition" required>
                                </div>
                
                                <div class="parentBtn">
                                    <button class="btn btn-send" v-on:click="invalid()" aria-label="send form">
                                        <div id="spin"></div>{{$t('dataFormT')['4']}}
                                    </button>
                                </div>
                            </form>
                            
                            <!-- ( END DOWNLOAD FORM ) -->
                        </div>
                        <p class="opacity-50 form-info">{{$t('dataFormT')['5']}}</p>
                    </div>
                    <!--  DISPLAY IF VALID FORM  -->
                    <div class="info-download">
                        <div class="info-download-ctn">
                            <p>{{$t('dataFormT')['6']}}</p>
                            <div class="loader">  
                                <div class="line"></div>
                                <div class="line"></div>
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>
                    <!--  ( END )  -->

                    <!--  DOWNLOAD FILE -->
                    <div class="downloadFile-box">
                        <a id="link-brochure" :href='this.$pathprod+"file/Brochure-HARMONY.pdf"' download="Brochure-HARMONY">link-brochure</a>
                        <a id="link-perspective" :href='this.$pathprod+"file/PERSPECTIVES_SITE_WEB_HARMONY.zip"' download="PERSPECTIVES_SITE_WEB_HARMONY">link-perspective</a>
                        <a id="link-all" :href='this.$pathprod+"file/Brochure_&_Perspectives_HARMONY.zip"' download="Brochure_&_Perspectives_HARMONY">link-all</a>
                    </div>
                    <!--   END   -->
                </div>
            </div>
        </div>

    </section>
</template>

<script>
    export default {
        name: 'telechargement',
        props: {
            msg: String,
        },
        metaInfo() {
            return { 
                title: "Harmony - Téléchargements",
                meta: [
                    { name: 'description', content:  'Télécharger la brochure HARMONY - Saint-ouen'},
                    { property: 'og:title', content: "Harmony - Téléchargements"},
                    { property: 'og:site_name', content: 'Harmony'},
                    { property: 'og:type', content: 'website'},    
                ]
            }
        },
        components: {
            VideoTelechargement: () => import('@/components/video/VideoTelechargement.vue'),
        },
        data() {
            return {
                formData: {
                    url:'harmony'
                },
                errors: {},
            }
        }, 
        methods: {
            onSubmit(e){
                //********* Download file *************
                    var val_brochure = document.querySelector("#Brochure").checked
                    var val_perspective = document.querySelector("#Perspective").checked
                    var link_brochure = document.querySelector("#link-brochure")
                    var link_perspective = document.querySelector("#link-perspective")
                    var link_all = document.querySelector('#link-all')

                    if(val_brochure == true && val_perspective == true){
                        link_all.click();
                    }else if(val_brochure == false && val_perspective == false){
                        alert("Veuillez sélectionner au moins 01 éléments")
                        return false
                    }else if(val_brochure == true){
                        link_brochure.click();
                    }else if(val_perspective == true){
                        link_perspective.click();
                    }
                //********* END Download file *************

                //Display waiting download message
                e.preventDefault
                setTimeout(() => {
                    var x = document.querySelectorAll(".invalid:invalid")
                    if(x.length == 0){
                        document.querySelector(".form-ctn").classList.add("slide-out")
                        document.querySelector(".info-download").style.opacity = "1"
                    }
                }, 10);
            
                //Send email
                // eslint-disable-next-line no-undef
                const sendData = function (data) {
                    data = new URLSearchParams(data)
                    return fetch('https://harmony-saintouen.com/app/api.php', {
                        method: "POST",
                        body: data,
                        mode: 'cors',
                        headers: {
                            'ContentType': "application/x-www-form-urlencoded; charset=UTF-8"
                        }
                    }).then((r) => r.json())
                }

                sendData(this.formData).then((r) => {
                    if (r.errors) {
                        this.errors = r.errors
                    }
                }, (e) => {
                    console.log('error', e)
                    })
            },

            //********* VALID FORMULAIRE *************
            invalid(){
                document.addEventListener('invalid', function(e){
                    e.target.classList.add("invalid");
                }, true);
            }
            //********* END FORMULAIRE *************
        },
        mounted(){

            //********* ANIMATION FORM LABEL *************
            var emailField = document.getElementById("email")
            emailField.addEventListener("input", function(){
                var emailTxt = document.querySelector(".txt-email");
                if (emailField.value != ""){
                    emailTxt.style.top = "-10px";
                    emailTxt.style.background = "#fafafa";
                    emailTxt.style.color = "black";
                }else{
                    emailTxt.style.top = "";
                    emailTxt.style.background = "";
                    emailTxt.style.color = "";
                }
            })

            setTimeout(() => {
                document.querySelector(".tl-ctnLeft .bg-hero").play()
            }, 1000);
        },
    }
</script>